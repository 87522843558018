<template>
    <div>
      <TextBox
        :cefrLevel="payload.cefr_level"
        :label="'Title'"
        :isTitle="true"
        placeholder="Title"
        v-model="title"/>
      <br>

      <TextBox
        :cefrLevel="payload.cefr_level"
        :label="'Rubric'"
        v-model="rubric"
        placeholder="Rubric"/>
      <br>

      <div class="audio-container">
        <AudioInput
          label="Assessment Audio"
          :status="status"
          :audioFilename.sync="audioFilename"
          :audioFileLocation.sync="audioFileLocation"
        />
      </div>

      <OptionsRadio
        v-model="question"
        :cefrLevel="payload.cefr_level"
        :last="true"/>

    </div>
</template>

<script>
import TextBox from '@/components/item/TextBox'
import OptionsRadio from '@/components/item/OptionsRadio'
import AudioInput from "@/components/item/AudioInput"
import { v4 as uuidv4 } from 'uuid'
import { mapState, mapGetters } from 'vuex'

export default {
    components:{
      TextBox,
      OptionsRadio,
      AudioInput,
    },
    data: () => ({
        template:'MCQ_LISTENING',
        title:'',
        rubric:'',
        url:'',
        uploadResponse: '',
        question: {
          question: '',
          question_id: uuidv4(),
          solution: [''],
          question_options:[
            {
                value:'',
                option_id: uuidv4()
            },
            {
                value:'',
                option_id: uuidv4()
            },
            {
                value:'',
                option_id: uuidv4()
            },
          ],
        },
        questions:[],
        audioFilename: "",
        audioFileLocation: "",
    }),
    computed: {
      ...mapState("item", ["payload", "isUpdate", "status"]),
      ...mapGetters({
        user: 'account/user',
        isBusy: 'item/isBusy',
      }),
      answer: {
        get: function() {
          return this.options.optionsArray[this.options.selectedOption].value;
        },
        set: function(value) {
          const option = this.options.optionsArray[this.options.selectedOption];
          option.value = value;
          this.options.optionsArray.splice(this.options.selectedOption, 1, option);
        }
      },
    },
    methods:{
        onSubmit() {
            // When creating an item, set the question's solution[id] to be the option_id of the first question_option
            if (!this.isUpdate) this.question.solution[0] = this.question.question_options[0].option_id

            const data = {
                template_type:this.payload.template ? this.payload.template : this.template,
                title:this.title,
                rubric:this.rubric,
                audio_file: this.audioFileLocation,
                assets: {
                  audio: [{
                    file_name: this.audioFilename,
                    file_path: this.audioFileLocation,
                  }],
                },
                questions: [this.question]
            }
          this.$store.commit('item/setPayload', data)
        },
    },
    beforeMount(){
      if (this.isUpdate && Object.keys(this.payload).length) {
        this.audio_file = this.payload.audio_file;
        this.audioFileLocation = this.payload.assets.audio[0].file_location || '';
        this.audioFilename = this.payload.assets.audio[0].file_name || '';
        this.title = this.payload.title || '';
        this.rubric = this.payload.rubric || '';

        // Make sure that the solution is first in the list of question options
        const { question, question_id, question_options, solution } = this.payload.questions[0];
        const solutionIndex = question_options.findIndex(option => option.option_id === solution[0]);
        const solutionOption = question_options[solutionIndex];
        question_options.splice(solutionIndex, 1);
        question_options.unshift(solutionOption);

        this.question = {
          question,
          question_id,
          question_options,
          solution,
        }
      }
    },
}

</script>

<style lang="scss" scoped>
.audio-container
{
  max-width: 545pt;
}

.answer
{
  background: rgb(228, 246, 228);
  color: rgb(17, 113, 17);
  border: none;
  border-bottom: 3pt solid rgb(17, 113, 17);
  font-weight: bold;
  padding: 10pt;
  padding-top: 7pt;
  width: unset;
  min-width: 40pt;
  text-align: center;
  font-size: 1em;
  display: inline;
}


.title
{
  font-size: 1.5em;
  font-weight: bold;
  padding: 10pt;
  width: 80%;
  text-align: left;
  border: unset;
  border-bottom: 3pt solid rgb(222, 222, 222);
}


.full-para
{
  flex-wrap: wrap;
  padding: 3pt;
  gap: 10pt;
  max-width: 500pt;
  overflow: hidden;
  word-wrap: normal;
  overflow-wrap: break-word;
}

.full
{
  height: 100pt;
}


.options
{
  display: flex;
  flex-direction: column;
  gap: 10;
}


</style>